import "./editor.scss"
import "../css/style.scss"

// Our modules / classes
// import MobileMenu from "./modules/MobileMenu"

// Instantiate a new object using our modules/classes
// const mobileMenu = new MobileMenu()

import ImagesLoaded from "imagesloaded"
import Lazyload from "lazyload"
import Swiper from "swiper/bundle"
// import Swiper, { Navigation } from "swiper/bundle"
import Isotope from "isotope-layout"

// Our modules / classes
import LoadMorePosts from "./modules/LoadMorePosts"
let loadMorePosts

var debug = true
const mobileBreakpoint = 782
let mobileView = false

const ScrollDelta = 10 // how many pixels to scroll to call scroll dependent function

let adminBarHeight = 0
let siteTitleHeight = 0
let titleHeight = 0
let navHeight = 0

let historyUpdate = false // to push to history or not
let autoplayOn = true
let autoplaySpeed = 800
let autoplayDelay = 3000
let orientationLandscape = true
let shuffleInterval
let startAutoShuffleTimeout

let lastScroll = 0
let prevScrollDelta = 0
let scrollYPositions = {}

let currentContentID = -1
let prevContentID = -1

let navOpen = false // initially closed
let navChanging = false
let navChangingTimeout = null
const navChangingTime = 400
let autoScrolling = false

let requestAllPartsInterval = null
let nowWithKeyboard = false // if true mobile keyboard is showing
let observer
let titleGrid // grid with isotope
let picGrid // grid with isotope arbiten pics links

let projectCategoryLinks
let projectTitleLinks
let projectPictureLinks

let viewTitlesIcon // in mobile view: lets you show/hide project titles in the archvive
let viewPicturesIcon // either titles or pictures selected
let viewCategoriesIcon

let mouseStartX, mouseStartY

jQuery(function ($) {
  // if (debug) console.log("document ready!")
  const $html = $("html")
  $html.addClass("js") // js is working
  // console.log("root url:", localized.root_url)

  if (checkFlexGap()) {
    document.documentElement.classList.add("flexbox-gap")
  } else {
    document.documentElement.classList.add("no-flexbox-gap")
  }

  // console.log("nonce", localized.nonce, localized.nonce_field);
  if ("scrollRestoration" in history) {
    history.scrollRestoration = "manual"
  }
  // for ios touch -> enable active state for links
  document.addEventListener(
    "touchstart",
    function () {
      // console.log("touchstart")
    },
    false
  )

  // const DateTime = luxon.DateTime;

  let pathname = window.location.pathname
  // console.log('pathname', pathname);
  // console.log('history state', history.state);

  let blogName = document.querySelector('meta[name="name"]').content
  const parts = blogName.split(" - ")
  blogName = parts[0]
  let blogDescription = parts[1]
  // const blogDescription = document.querySelector('meta[name="description"]').content

  console.log(blogName, blogDescription)

  // -----------------------------------------------
  const openMenu = $(".nav-icon")
  const siteContainer = $(".site-container")
  const siteHeader = $(".site-header")
  const navContainer = $(".site-menu")

  const siteTitle = $(".site-title")
  const siteTitleLink = $(".site-title__link")

  const menuItems = $(".menu-item")
  const menuNavLinks = $(".menu-item__link")

  const contentContainer = $(".content-container")

  // -----------------------------------------------------------------------------------------------------

  const $body = $("body")
  const $mainMenu = $(".menu-main")
  const homeID = $body.data("home-id").toString() // post id of the home page as string
  const numberOfMenuItems = contentContainer.data("menu-items")
  $(":root").css("--number-menu-items", numberOfMenuItems)

  setLandscape()
  touchSupport()

  // set background color from customizer
  document.documentElement.style.setProperty("--bgcolor", $("body").data("bgcolor"))
  document.documentElement.style.setProperty("--highlight-color", $("body").data("highlightcolor"))

  calcVhPropertyFromClientHeight() // for mobile viewport height on ios
  setAdminBarHeight()
  checkMobileView()

  // hide and fade incontent elements with visibility hidden
  contentContainer.hide()
  contentContainer.css("visibility", "visible")
  contentContainer.fadeIn()

  let postsContainer = $(".posts-container")

  // let postItemContainers = $(".post-item-container")
  // postItemContainers.hide() // hide all post items

  // menu and title navigation ------------------------------------------------------------------------------------------------
  menuNavLinks.add(siteTitleLink).on("click", function (e) {
    console.log("menu nav link clicked")

    const menuItem = $(this).parent()
    const isPage = menuItem.hasClass("menu-item-object-page")
    const isHome = menuItem.hasClass("menu-item-home")
    const isProject = menuItem.hasClass("menu-item-object-arbeit")
    const isArchive = menuItem.hasClass("menu-item-type-post_type_archive")
    const id = isHome ? homeID : menuItem.data("object-id")

    console.log("Page:", isPage, "object id", id, homeID, "is home: ", isHome)

    if (isPage || isProject || isHome || isArchive) {
      // links in the menu are either pages or project archive
      e.preventDefault()

      const slug = $(this).attr("href").split(`${window.location.origin}/`)[1]
      console.log("slug", slug)

      // history only gets updated when clicking on a different menu item, and not when navigating by url (gotolocation)
      const ref = `${window.location.origin}/${slug}`

      if (window.location.href !== ref) {
        window.history.pushState(null, null, ref)
        // console.log("updated history ", ref);

        // update the title tag when menu click. page load sets the title tag automatically (theme support 'title-tag')
        if (slug !== "") {
          // not home -> page | blogname
          document.title = $(this).children().text() + " | " + blogName
        } else {
          // is home -> blogname | blogdescription
          document.title = blogName + " | " + blogDescription
        }

        if (isHome) {
          openPageLink($(this), id)
        } else if (isPage) {
          openPageLink($(this))
        } else if (isProject) {
          // project
          if (isArchive) {
            openProjectArchive(slug)
          } else {
            openProjectWithID(id)
          }
        }
      }
    }
  })

  openMenu.on("click", function () {
    openNav()
  })

  function openNav() {
    openMenu.addClass("hidden")
    navContainer.addClass("show")
  }
  function closeNav() {
    openMenu.removeClass("hidden")
    navContainer.removeClass("show")
  }
  // --------------------------------------------------------------------------------------------------------------------------------------------------------
  function openPageLink($link, pageID) {
    console.log("open page link", $link.attr("href"))

    const menuItem = $link.parent() // menu item of link

    pageID = pageID ?? menuItem.data("object-id")

    setCurrentMenuItem(menuItem)

    openPageWithID(pageID)
  }

  function setCurrentMenuItem(menuItem) {
    //
    menuItems.removeClass("current-menu-item") // remove current menu from all menu items
    menuItem.addClass("current-menu-item") // new current menu item

    const currentInd = menuItem.index()
    console.log("curr menu index", currentInd)
    menuItems.each(function (ind) {
      if (ind <= currentInd) {
        $(this).addClass("open")
      } else {
        $(this).removeClass("open")
      }
    })
    console.log("menu item index", menuItem.index())
  }

  function openPageWithID(pageID) {
    console.log("open page with id", pageID)
    if (pageID && pageID !== currentContentID) {
      // hide all other page and post item content and the images
      // $(".post-item-container").not(`#${pageID}`).hide() // hide page content
      contentContainer.removeClass("posts-container--visible")

      setCurrentContentID(pageID)

      if ($(`div#${pageID}`).length == 0) {
        // post-item-container for this page not yet existing -> get the page
        // console.log("page not yet..", pageID, homeID === pageID ? "is home page" : "is page")
        // page container does not exist yet -> load page with page id

        // get page content
        $.getJSON(localized.root_url + "/wp-json/wp/v2/pages/" + pageID, page => {
          console.log("got page", page)
          let pageClasses = ""
          if (page.template == "page-home.php") {
            pageClasses = "grid-layout"
          } else if (page.template == "page-info.php") {
            pageClasses = "grid-layout-4-cols"
          }

          // add page content
          const menuItem = $(`.menu-item[data-object-id=${pageID}]`)
          menuItem.append(`
            <div id="${pageID}" class="post-item-container post-type-page initialized" data-title="${page.title.rendered}" data-history="${page.slug}">

              <div class="post-item-content ${pageClasses}">    

                ${page.content.rendered ? `<div class="content">${page.content.rendered}</div>` : ``}

                ${page.address ? `<div class="address">${page.address}</div>` : ``}

                ${
                  page.infos.length
                    ? `${page.infos
                        .map(
                          (info, index) => `
                  <div class="info-${index + 1}">
                    <div class="thin">${info.title}</div>
                    ${info.content}
                  </div>`
                        )
                        .join("")}`
                    : ``
                }

                ${
                  page.pictures?.length > 0
                    ? `
                    ${page.pictures
                      ?.map(
                        pic => `
                          <div class="image-container square">
                            <img class="lazyload"
                            data-src="${pic.src}"
                            data-srcset="${pic.srcset}"
                            sizes="100vw, (min-width: 782px) 50vw"
                            alt="${pic.alt}">
                          </div>`
                      )
                      .join("")}`
                    : ``
                }

              </div>
            </div>
          `)

          const newPage = menuItem.children(`#${pageID}`)
          newPage.hide()

          newPage.imagesLoaded(function () {
            addLazyloadToElement(newPage)
            initPage(newPage) // add event listeners
            newPage.fadeIn()
          })
        })
      } else {
        // --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
        // page container already exists -> show the page
        // - either loaded by php page template, then we have to initialize the content first
        // - or already loaded before by ajax and is initialized
        console.log("page exists", pageID)

        const page = $(`#${pageID}`)

        page.hide()

        if (!page.hasClass("initialized")) {
          // page not yet initialized because it was loaded by php
          console.log("init existing page")
          addLazyloadToElement(page)

          initPage(page) // add event listeners
          page.imagesLoaded(function () {
            // console.log("page images loaded")
            page.fadeIn()
          })

          page.addClass("initialized")
        } else {
          // page content existing and already initialized -> previously viewed
          // console.log("page already inited")
          page.fadeIn()
        }
      }
    }
  }

  // function handleInternalLinks(page) {
  //   // find internal links on a page
  //   const links = page.find(`a[href*="${localized.root_url}"]`)

  //   links.on("click", function (e) {
  //     // update history
  //     const ref = $(this).attr("href")

  //     if (!ref.includes("/wp-content/uploads/")) {
  //       // not a link to a local file
  //       e.preventDefault()

  //       if (window.location.href !== ref) {
  //         window.history.pushState(null, null, ref)
  //         // console.log("updated history ", ref)

  //         // update the title tag when menu click. page load sets the title tag automatically (theme support 'title-tag')
  //         document.title = $(this).text() + " | " + blogName
  //       }

  //       const path = $(this).attr("href").replace(localized.root_url, "")
  //       gotoLocation(path)
  //     }
  //   })
  // }

  // open the single projects links from the archive page
  $(document).on("click", ".post-item-content__project.preview-space", function (e) {
    e.preventDefault()

    // update history
    const ref = $(this).attr("href")

    if (window.location.href !== ref) {
      window.history.pushState(null, null, ref)
      // console.log("updated history ", ref)

      // update the title tag when menu click. page load sets the title tag automatically (theme support 'title-tag')
      document.title = $(this).data("title") + " | " + blogName
    }

    openProjectWithID($(this).data("id"))
  })

  function openProjectWithSlug(slug) {
    console.log("open project with slug", slug)

    // check if single project or archive and get the id
    $.getJSON(`${localized.root_url}/wp-json/pathtopost/v1/get?path=${slug}`, post => {
      console.log("got post", post)
      if (post.ID > 0) {
        // project found
        if (debug) console.log("project id found -> open project with id", post.ID)
        openProjectWithID(post.ID)
      } else {
        // is not a single project -> project archive /arbeiten or /arbeiten/category/xxxx/
        openProjectArchive(slug)
      }
    })
  }

  function openProjectWithID(projectID) {
    // if one project is opened all projects are loaded. but with image lazy loading
    if (projectID) {
      console.log("open project with id", projectID)

      // make posts container visible
      contentContainer.addClass("posts-container--visible")

      // get arbeiten menu item
      const menuitem = menuItems.filter('[data-slug="arbeiten/"]')
      setCurrentMenuItem(menuitem)

      setCurrentContentID(projectID)

      // check if posts container exists

      if (postsContainer.length == 0) {
        menuitem.append(`
        <div class="posts-container"></div>
        `)
        postsContainer = $(".posts-container")
      } else {
        // hide all other projects in the posts container
        $(".posts-container > .post-item-container").not(`#${projectID}`).hide()
      }

      // load project if not existing
      if ($(`div#${projectID}`).length == 0) {
        console.log("no project page")

        $.getJSON(localized.root_url + "/wp-json/wp/v2/arbeit/" + projectID, project => {
          console.log(project)

          postsContainer.append(
            `            
            <div id="${project.id}" class="post-item-container post-type-arbeit initialized" data-title="${project.title.rendered}" data-history="${project.slug}" >
              
              <div class="post-item-content grid-layout-3-cols">

                <div class="post-item__slider">
                  ${
                    project.pictures?.length > 0
                      ? `
                  <div class="swiper ${project.pictures.length === 1 ? "only-one-slide " : ""}">

                    <div class="swiper-wrapper">
                      ${project.pictures
                        .map(
                          pic => `
                          <div class="swiper-slide ${pic.class}">
                            <div class="img-container">
                              <img class="swiper-lazy ${pic.class}"
                              data-src="${pic.src}"
                              data-srcset="${pic.srcset}"
                              sizes="(max-width: 781px) 100vw, 66vw"
                              alt="${pic.alt}">
                            </div>
                          </div>
                          `
                        )
                        .join("")}
                    </div>
                  </div>`
                      : ``
                  }
                </div>

                <div class="post-item__infos">
                  <div class="info-wrapper">
                    <h1 class="title">${project.title.rendered}</h1>
                    <div class="content">${project.content.rendered}</div>

                    ${
                      project.stichworte
                        ? `<div class="stichwort-container">${project.stichworte
                            .map(
                              item => `
                        <div class="stichwort-item">${item}</div>`
                            )
                            .join("")}</div>`
                        : ``
                    }

                    ${
                      project.links
                        ? `<div class="links-container">${project.links
                            .map(
                              item => `
                        <a class="link-item" href="${item.url}" target="${item.target}">${item.title}</a>`
                            )
                            .join("")}</div>`
                        : ``
                    }                   

                  </div>

                  
                  ${
                    project.categories
                      ? `<div class="category-container">${project.categories
                          .map(
                            item => `
                      <a class="post-item__category-link category-item" href="${localized.root_url + "/arbeiten/kategorie/" + item.slug}" data-slug="${item.slug}">${item.name}</a>`
                          )
                          .join("")}</div>`
                      : ``
                  }                   


                </div>


              </div>

              </div>    
            </div>
            `
          )

          project = postsContainer.children(`#${projectID}`)
          project.hide()
          initProject(project)

          project.imagesLoaded(function () {
            project.fadeIn()
          })
        })
      } else {
        console.log("project page exists already")

        const project = $(`#${projectID}`)
        project.hide()

        if (!project.hasClass("initialized")) {
          // project not yet initialized because it was loaded by php
          console.log("init existing project project")
          initProject(project)

          project.imagesLoaded(function () {
            // console.log("project images loaded")
            project.fadeIn()
          })

          project.addClass("initialized")
        } else {
          // project content existing and already initialized -> previously viewed
          // console.log("project already initialized")
          project.fadeIn()
        }
      }
    }
  }

  function initProject(project) {
    initCategoryLinks(project)
    createProjectSwiper(project.find(".swiper"))
  }

  function setCurrentMenuForProject(cat) {
    //
    menuItems.removeClass("current-menu-item") // remove current menu from all menu items

    if (cat == "" || !cat) {
      cat = "arbeit"
    }
    cat = `/${cat}/`

    let index
    menuItems.each(function (ind) {
      if ($(this).children("a").attr("href").includes(cat)) {
        index = ind
        return false
      }
    })
    const menuItem = menuItems.eq(index)

    menuItem.addClass("current-menu-item") // new current menu item
  }

  function getMenuItemBySlug(slug) {
    // console.log("get menu item by slug:", slug)
    let found = false
    let index = 0
    menuItems.each(function (ind) {
      if ($(this).children("a").attr("href").includes(slug)) {
        found = true
        index = ind
        // console.log("menu link found", $(this).children("a").attr("href"), index)
        return false
      }
    })
    if (found) {
      return menuItems.eq(index)
    } else return null
  }

  // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------
  function openProjectArchive(slug) {
    console.log("open the arbeiten archive", slug)

    let kategorie = ""
    if (slug.includes("arbeiten/kategorie/")) kategorie = slug.replace("arbeiten/kategorie/", "")
    if (kategorie !== "") kategorie = kategorie.replaceAll("/", "")
    else kategorie = "arbeiten"

    console.log("category:", kategorie)

    // get arbeiten menu item
    const menuitem = menuItems.filter('[data-slug="arbeiten/"]')
    setCurrentMenuItem(menuitem)

    // hide posts container containing all arbeiten posts
    contentContainer.removeClass("posts-container--visible")
    let projectArchive = $("#arbeit-archive")

    setCurrentContentID("arbeit-archive")

    if (projectArchive.length == 0) {
      // no archive yet -> load all arbeiten
      if (debug) console.log("no project archive yet")

      const item = menuItems.filter('[data-slug="arbeiten/"]')

      item.append(`
        <div id="arbeit-archive" class="post-item-container post-type-arbeit post-item-archive initialized" data-title="Arbeiten" data-history="arbeiten">
          <div class="post-item-content grid-layout-3-cols">
            <div class="post-item__categories">
              <a class="post-item__category-link selected" href="${localized.root_url + "/arbeiten/"}" data-slug="arbeiten">Alles</a>
            </div>
            <div class="post-item__titles"></div>
            <div class="post-item__pictures"></div>
          </div>
        </div>`)

      const categoriesSection = item.find(".post-item__categories")
      const titlesSection = item.find(".post-item__titles")
      const picturesSection = item.find(".post-item__pictures")

      // get the categories
      $.getJSON(localized.root_url + "/wp-json/wp/v2/kategorie?per_page=100", categories => {
        console.log("kategorien", categories)
        categoriesSection.append(`
              ${categories
                .map(category => {
                  return `${category.count > 0 ? `<a class="post-item__category-link" href="${category.link}" data-slug="${category.slug}">${category.name}</a>` : ``}`
                })
                .join("")}
              
              `)

        // add event listeners for category links
        projectArchive = $("#arbeit-archive")
        initCategoryLinks(projectArchive)
      })

      $.getJSON(localized.root_url + "/wp-json/arbeiten/v1/get", projects => {
        console.log("arbeiten", projects)

        titlesSection.append(`

          ${projects
            .map(project => {
              console.log(project)
              return `<a class="post-item__title-link ${project.categories}" 
                          href="${localized.root_url + "/arbeiten/" + project.slug}" data-id="${project.id}" data-history="${project.slug}" data-title="${project.title}">
                          ${project.title}
                      </a>`
            })
            .join("")}

            `)

        picturesSection.append(`

        ${projects
          .map(project => {
            // console.log(project)
            return `<a class="post-item__picture-link${project.categories.includes(kategorie) || kategorie === "arbeiten" ? `` : ` hidden`} ${project.categories}" 
                        href="${localized.root_url + "/arbeiten/" + project.slug}" data-id="${project.id}" data-slug="${project.slug}" data-title="${project.title}"
                        data-cat="${project.data?.cat}">
                        ${
                          project.featured_image
                            ? `<div class="img-container ${project.featured_image.class}"><img class="lazyload" 
                          data-src="${project.featured_image.src}" 
                          data-srcset="${project.featured_image.srcset}" 
                          data-width="${project.featured_image.width}"
                          data-height="${project.featured_image.height}"
                          sizes="(max-width: 781px) 100vw, 30vw"
                          alt="${project.featured_image.alt}"></div>`
                            : ``
                        }
                        
                    </a>`
          })
          .join("")}
          `)

        projectArchive = $("#arbeit-archive")

        projectArchive.imagesLoaded(function () {
          initPictureLinks(projectArchive)
          initViewSelectors()
          projectArchive.fadeIn()
        })
      })
    } else {
      console.log("project archive exists")
      //
      if (!projectArchive.hasClass("initialized")) {
        // archive loaded by archive.php
        console.log("init the project archive")
        initProjectArchive(projectArchive)

        projectArchive.addClass("initialized")
        projectArchive.fadeIn()
      } else {
        filterProjects(kategorie)
        projectArchive.fadeIn()
      }
    }
  }

  function initProjectArchive(projectArchive) {
    initCategoryLinks(projectArchive)
    initPictureLinks(projectArchive)
    initViewSelectors()
  }

  function initCategoryLinks(page) {
    console.log("init category links", page)

    // add eventlistener for category links
    let links
    if (page.attr("id") === "arbeit-archive") {
      projectCategoryLinks = page.find($(".post-item__category-link"))
      links = projectCategoryLinks
    } else {
      links = page.find($(".post-item__category-link"))
    }

    links.on("click", function (e) {
      e.preventDefault()
      const slug = $(this).data("slug")

      if ($("#arbeit-archive").length) {
        // archive exists
        console.log("archive exists -> filter projects", slug)

        filterProjects(slug)

        // update history
        let ref
        if (slug === "arbeiten") ref = `${window.location.origin}/${slug}/`
        else ref = `${window.location.origin}/arbeiten/kategorie/${slug}/`

        if (window.location.href !== ref) {
          window.history.pushState(null, null, ref)
          // console.log("updated history ", ref);
          if (slug === "arbeiten") {
            document.title = "Arbeit | " + blogName
          } else {
            document.title = $(this).text() + " | " + blogName
          }
        }
      } else {
        // no archive yet -> load it
        openProjectArchive(`/arbeiten/kategorie/${slug}/`)
      }
    })
  }

  function initViewSelectors() {
    // add eventlisteners for the mobile view selectors
    viewTitlesIcon = $(".menu-item__view-titles-icon")
    if (viewTitlesIcon.length) {
      if (!mobileView || viewTitlesIcon.hasClass("selected")) {
        $(".post-item__titles").show()
      } else {
        $(".post-item__titles").hide()
      }
      viewTitlesIcon.on("click", function () {
        viewPicturesIcon.removeClass("selected")
        $(this).addClass("selected")
        $(".post-item__titles").slideDown(() => {
          if (titleGrid) titleGrid.arrange()
        })
        $(".post-item__pictures").slideUp()
      })
    }

    viewPicturesIcon = $(".menu-item__view-pictures-icon")
    if (viewPicturesIcon.length) {
      if (!mobileView || viewPicturesIcon.hasClass("selected")) {
        $(".post-item__pictures").show()
      } else {
        $(".post-item__pictures").hide()
      }
      viewPicturesIcon.on("click", function () {
        viewTitlesIcon.removeClass("selected")
        $(this).addClass("selected")
        $(".post-item__titles").slideUp()
        $(".post-item__pictures").slideDown(() => {
          if (picGrid) picGrid.arrange()
        })
      })
    }

    viewCategoriesIcon = $(".menu-item__view-categories-icon")
    if (viewCategoriesIcon.length) {
      if (!mobileView || viewCategoriesIcon.hasClass("selected")) {
        $(".post-item__categories").show()
      } else {
        $(".post-item__categories").hide()
      }

      viewCategoriesIcon.on("click", function (e) {
        // e.stopPropagation()
        const catContainer = $(".post-item__categories")
        if ($(this).hasClass("selected")) {
          $(this).removeClass("selected")
          catContainer.slideUp()
        } else {
          $(this).addClass("selected")
          catContainer.slideDown()
        }
      })
    }
  }

  function initPictureLinks(projectArchive) {
    addLazyloadToElement(projectArchive)

    projectTitleLinks = $(".post-item__title-link")
    projectPictureLinks = $(".post-item__picture-link")

    // make title masonry
    titleGrid = new Isotope(".post-item__titles", {
      itemSelector: ".post-item__title-link",
      layoutMode: "vertical",
      stagger: 50
    })

    // make picture Isotope
    projectArchive.find(".post-item__pictures").imagesLoaded(function () {
      console.log("project archive init images loaded")

      picGrid = new Isotope(".post-item__pictures", {
        itemSelector: ".post-item__picture-link",
        layoutMode: "vertical",
        percentPosition: true,
        stagger: 50
      })
    })

    // add click handler to titles and pictues -> to open project detail
    projectTitleLinks.add(projectPictureLinks).on("click", function (e) {
      e.preventDefault()
      // update history
      const ref = $(this).attr("href")

      if (window.location.href !== ref) {
        window.history.pushState(null, null, ref)
        // console.log("updated history ", ref)

        // update the title tag when menu click. page load sets the title tag automatically (theme support 'title-tag')
        document.title = $(this).data("title") + " | " + blogName
      }

      const id = $(this).data("id")
      openProjectWithID(id)
    })
  }

  function filterProjects(slug) {
    // hide project detail page
    contentContainer.removeClass("posts-container--visible")

    // show current category link
    projectCategoryLinks.removeClass("selected")
    projectCategoryLinks.filter(`[data-slug=${slug}]`).addClass("selected")

    // remove hidden class (when loaded with category)
    projectPictureLinks.removeClass("hidden")
    projectTitleLinks.removeClass("hidden")

    // filter what to show
    let filter = ""
    if (slug === "arbeiten") filter = "*"
    else filter = `.cat-${slug}`

    // filter title links
    if (titleGrid) {
      titleGrid.arrange({
        filter: filter
      })
    }

    // filter picture links
    if (picGrid) {
      picGrid.arrange({
        filter: filter
      })
    }
  }

  // function openBlogPage() {
  //   // console.log("open the blog page")

  //   contentContainer.fadeIn()
  //   // hide all other page and post items
  //   let blogPage = $("#blog-page")
  //   $(".post-item-container").not(blogPage).hide()

  //   setCurrentContentID("blog-page")
  //   setCurrentMenuItem($(".menu-item-home"))

  //   if (blogPage.length == 0) {
  //     // no blog page yet
  //     // console.log("no blog page yet")
  //     // $.getJSON(localized.root_url + "/wp-json/wp/v2/posts/", posts => {
  //     // console.log("posts", posts)

  //     postsContainer.append(`
  //       <div id="blog-page" class="post-item-container initialized" data-title="Journal" data-history="journal">
  //         <div class="title-level-1">Journal</div>
  //         <div class="one-line-space"></div>
  //         <div class="top-space"></div>

  //       </div>
  //                     `)

  //     blogPage = $("#blog-page")
  //     blogPage.hide()
  //     handleInternalLinks(blogPage)

  //     // add load more posts functionality -> create LoadMorePosts object with news page id
  //     loadMorePosts = new LoadMorePosts("blog-page")
  //     loadMorePosts.loadMorePosts()

  //     blogPage.imagesLoaded(function () {
  //       addLazyloadToElement(blogPage)
  //       blogPage.fadeIn()
  //     })
  //     // })
  //   } else {
  //     // console.log("blog page exists")

  //     if (!blogPage.hasClass("initialized")) {
  //       loadMorePosts = new LoadMorePosts("blog-page")
  //       handleInternalLinks(blogPage)
  //       // archive loaded by archive.php
  //       // console.log("init the blog page")
  //       // initblogPage(blogPage)
  //       addLazyloadToElement(blogPage)
  //       // blogPage.hide()
  //       blogPage.addClass("initialized")
  //       blogPage.imagesLoaded(function () {
  //         blogPage.fadeIn()
  //       })
  //     } else {
  //       blogPage.fadeIn()
  //     }
  //   }
  // }

  function showHideProjects(projects, kat) {
    projects.hide()
    projects.each(function (index) {
      // console.log(index)

      const el = $(this)
      setTimeout(function () {
        if (kat == "" || el.data("cat") == kat) {
          // el.removeClass("noshow")
          el.fadeIn()
        } else {
          // el.hide()
        }
      }, index * 100)
    })
  }

  function initPage(page) {
    page.find(".image-container").on("click", function () {
      // open arbeiten archive

      const slug = "arbeiten/"
      const ref = `${window.location.origin}/${slug}`

      if (window.location.href !== ref) {
        window.history.pushState(null, null, ref)
        // console.log("updated history ", ref);

        document.title = "Arbeiten | " + blogName
      }

      openProjectArchive(slug)
    })
  }

  function addLazyloadToElement($elem) {
    if ($elem.length) {
      // https://github.com/tuupola/lazyload
      // $("img.lazyload").lazyload();
      // lazyload();

      // const elem = document.querySelector(`[id="${elemID}"]`);
      const elem = $elem[0]
      const images = elem.querySelectorAll("img.lazyload")
      // console.log("lazyload images", images, " in container ", $elem)

      new Lazyload(images, {
        root: null,
        rootMargin: "50px",
        threshold: 0
      })

      // add load event listener for lazyload images
      $elem.find(`img.lazyload`).on("load", function () {
        // console.log("img on elem xx loaded", $elem.attr('id'));
        $(this).addClass("loaded") // add loaded class to image -> fade in with css opacity 1
      })
    }
  }

  // init single arbeit swiper
  function createProjectSwiper($swiper) {
    const swiper = new Swiper($swiper[0], {
      direction: "horizontal",
      slidesPerView: "auto",
      spaceBetween: 4,
      freeMode: true,
      touchStartPreventDefault: false,
      // loop: true,
      // loopedSlides: 5,
      // loopedSlidesLimit: false,
      init: true,
      // autoHeight: true, // slider css height = auto
      // Disable preloading of all images
      preloadImages: false,
      // Enable lazy loading
      lazy: {
        loadPrevNext: true,
        loadOnTransitionStart: true,
        checkInView: false
      },
      mousewheel: true,
      // slideToClickedSlide: true,
      keyboard: true,
      breakpoints: {
        782: {
          direction: "vertical"
        }
      }
    })

    // $(".post-item__slider").mousedown(function () {
    //   console.log("mouse down down")
    // })

    $swiper[0].addEventListener(
      "mousedown",
      e => {
        mouseStartX = e.clientX
        mouseStartY = e.clientY
      },
      false
    )

    $swiper.on("click", function (e) {
      if (mobileView) {
        if (e.clientX < mouseStartX + 10 && e.clientX > mouseStartX - 10) {
          if (e.clientX > $swiper.width() / 2) $swiper[0].swiper?.slideNext()
          else $swiper[0].swiper?.slidePrev()
        }
      } else {
        // desktop -> slider is vertical
        if (e.clientY < mouseStartY + 10 && e.clientY > mouseStartY - 10) {
          if (e.clientY < $swiper.height() / 2) $swiper[0].swiper?.slideNext()
          else $swiper[0].swiper?.slidePrev()
        }
      }
    })
  }

  // handle pathname ------------------------------------------------------------------------------------------------
  gotoLocation(pathname)

  function gotoLocation(pathname) {
    console.log("goto to location:", pathname)

    // get post info
    if (pathname === "/") {
      // is home page
      console.log("is home")
      openPageLink(siteTitleLink)
      // --------------------------------------------------------
    } else if (pathname.startsWith("/arbeiten/")) {
      // single arbeit or arbeit archive
      openProjectWithSlug(pathname)
    } else {
      // not home nor project
      console.log("not home check menu items", $("li.menu-item").length)
      // first check if it's a menu item. exclude the lang menu item
      let found = false
      menuNavLinks.each(function () {
        if ($(this).attr("href").includes(pathname)) {
          found = true
          console.log("menu link found", $(this).attr("href"))
          // if page
          if ($(this).parent().hasClass("menu-item-object-page")) openPageLink($(this))
          // if project
          else if ($(this).parent().hasClass("menu-item-object-arbeit")) openProjectWithSlug(pathname)
          return false
        }
      })
      if (!found) {
        // was not a menu link
        console.log("not menu link")
        // console.log("url is something else")
        // something other -> get post type for path
        $.getJSON(`${localized.root_url}/wp-json/pathtopost/v1/get?path=${pathname}`, post => {
          // console.log(post)
          if (post.ID > 0) {
            // post found
            if (post.post_type == "page") {
              // page that's not in the menu
              openPageWithID(pageID)
            } else if (post.post_type == "arbeit") {
              openProjectWithID(post.ID)
            } else {
              // hide all other post items
              $(".post-item-container").not(`#${post.ID}`).hide()
              $("li.menu-item").removeClass("current-menu-item")
              $(`#${post.ID}`).fadeIn()
            }
          } else {
            // is not a post -> show all post item container
            // hide all other post items
            $(".post-item-container").fadeIn()
            $("li.menu-item").removeClass("current-menu-item")
          }
        })
      }
    }
  }

  //
  function setCurrentContentID(id) {
    prevContentID = currentContentID
    currentContentID = id
    // console.log("current content:", currentContentID, prevContentID)
  }

  let resizeTimeout = null
  let resizeTimeout2 = null

  window.addEventListener("resize", () => {
    checkMobileView()

    clearTimeout(resizeTimeout)
    resizeTimeout = setTimeout(() => {
      touchSupport()
      onOrientationChange()
      setAdminBarHeight()
      calcVhPropertyFromClientHeight()
      checkMobileView()
    }, 500)

    clearTimeout(resizeTimeout2)
    resizeTimeout2 = setTimeout(() => {
      // for ios safari to get correct window height
      calcVhPropertyFromClientHeight()
    }, 1000)
  })

  function touchSupport() {
    const touchsupport = "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0

    if (!touchsupport) {
      // browser doesn't support touch
      // document.documentElement.className += " non-touch"
      $("html").addClass("non-touch")
      $("html").removeClass("touch")
    } else {
      $("html").removeClass("non-touch")
      $("html").addClass("touch")
    }
  }

  function checkMobileView() {
    if ($(window).width() < mobileBreakpoint) {
      mobileView = true
      $("html").addClass("mobile-view")
      $("html").removeClass("desktop-view")
      if (viewCategoriesIcon) {
        if (viewCategoriesIcon.hasClass("selected")) $(".post-item__categories").show()
        else $(".post-item__categories").hide()
        if (!viewTitlesIcon.hasClass("selected")) $(".post-item__titles").hide()
        if (!viewPicturesIcon.hasClass("selected")) $(".post-item__pictures").hide()
      }
    } else {
      mobileView = false
      $("html").removeClass("mobile-view")
      $("html").addClass("desktop-view")
      $(".post-item__categories").show()
      $(".post-item__titles").show()
      $(".post-item__pictures").show()
    }
    console.log("window width", $(window).width())
  }

  function onOrientationChange() {
    // detect orientation change
    if ((orientationLandscape && window.innerWidth < window.innerHeight) || (!orientationLandscape && window.innerWidth >= window.innerHeight)) {
      setLandscape()
    }
  }

  function setLandscape() {
    // store new orientation
    orientationLandscape = window.innerWidth >= window.innerHeight

    if (orientationLandscape) {
      $("html").addClass("orientation-landscape")
      $("html").removeClass("orientation-portrait")
    } else {
      $("html").removeClass("orientation-landscape")
      $("html").addClass("orientation-portrait")
    }
    // console.log("orientation changed, landscape:", orientationLandscape);
  }

  function setAdminBarHeight() {
    let wpabh = 0
    const adminBar = $("#wpadminbar")
    if (adminBar.length) {
      wpabh = adminBar.outerHeight()
    }
    adminBarHeight = wpabh
    $(":root").css("--adminBarHeight", `${adminBarHeight}px`)
  }

  function navAndTitleHeight() {
    // siteTitleHeight = siteTitle.outerHeight()
    // $(":root").css("--siteTitleHeight", `${siteTitleHeight}px`)
    // console.log("title height", siteTitleHeight)

    // get nav height only when nav closed
    if (!navOpen) {
      navHeight = navContainer.outerHeight()
      $(":root").css("--navHeight", `${navHeight}px`)
    }
    // console.log("navheight", navHeight)
  }

  function calcVhPropertyFromClientHeight() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    // let vh = document.documentElement.clientHeight * 0.01
    const vh = window.innerHeight * 0.01
    // console.log("height: ", vh * 100);
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`)
    // if (debug) console.log("client height: ", document.documentElement.clientHeight);

    //
    // const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
    // const h = document.documentElement.clientHeight
    // console.log("ios vh:", vh * 100, "vh:", h)
  }

  // reload page on broswer back / forward
  window.onpopstate = function (event) {
    // console.log("on popstate", event);
    gotoLocation(window.location.pathname)
  }

  jQuery(document.body).on("post-load", function () {
    // New content has been added to the page.
    // console.log("post-load")
  })

  // for desktop: overflow auto for .content-container not body -> get scroll position from content-container
  document.querySelector(".content-container").addEventListener("scroll", function (e) {
    // console.log("scroll in content container", e.target.scrollTop)
    scrollYPositions[currentContentID] = e.target.scrollTop
  })

  // document.addEventListener("scroll", function (e) {
  //   const currentScroll = window.pageYOffset
  //   // const currentScroll = window.scrollY;
  //   const scrollDelta = 0.5 * (prevScrollDelta + (currentScroll - lastScroll))

  //   // console.log("scroll: ", currentScroll)
  //   // console.log("scroll: ", currentScroll, window.scrollY, lastScroll, scrollDelta, prevScrollDelta)

  //   prevScrollDelta = scrollDelta

  //   // // store scroll y for page contents
  //   // if (!navOpen) {
  //   //   scrollYPositions[currentContentID] = currentScroll
  //   //   // console.log(scrollYPositions)
  //   // }

  //   // console.log(scrollYPositions);
  //   if (window.scrollY === 0) {
  //     // on top
  //     openNav()
  //   } else if (currentScroll > lastScroll && scrollDelta > 5 && !navChanging && !autoScrolling) {
  //     // reduce sensitivity
  //     // down
  //     closeNav()
  //     // console.log("down - close nav")
  //   } else if (currentScroll < lastScroll && scrollDelta < -5 && window.scrollY > navHeight + siteTitleHeight && !navOpen && !autoScrolling) {
  //     // UP --- UP
  //     // console.log("up")
  //   }

  //   lastScroll = Math.max(0, currentScroll)
  // })

  function getIDFromClasslist(elem) {
    // get page id from menu item classlist (wpse-object-id-XX)
    let classlist = elem.attr("class").split(/\s+/)
    let pageID
    $.each(classlist, function (index, item) {
      if (item.startsWith("wpse-object-id-")) {
        const splits = item.split("wpse-object-id-")
        if (splits.length > 1) pageID = splits[1]
        return false
      }
    })
    return pageID
  }

  function checkFlexGap() {
    // create flex container with row-gap set
    var flex = document.createElement("div")
    flex.style.display = "flex"
    flex.style.flexDirection = "column"
    flex.style.rowGap = "1px"
    flex.style.position = "absolute"

    // create two, elements inside it
    flex.appendChild(document.createElement("div"))
    flex.appendChild(document.createElement("div"))

    // append to the DOM (needed to obtain scrollHeight)
    document.body.appendChild(flex)
    var isSupported = flex.scrollHeight === 1 // flex container should be 1px high from the row-gap
    flex.parentNode.removeChild(flex)

    return isSupported
  }

  // function observeElement($this) {
  //   if (observer) {
  //     observer.disconnect()
  //   } else {
  //     observer = new window.IntersectionObserver(
  //       ([entry]) => {
  //         if (entry.isIntersecting) {
  //           // console.log("ENTER")
  //         } else {
  //           // console.log("LEAVE")
  //         }
  //       },
  //       {
  //         root: null,
  //         threshold: 0.01 // set offset 0 means trigger if atleast 0% of element in viewport
  //       }
  //     )
  //   }
  //   observer.observe($this[0])
  // }
})
